<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>{{title}}</b-card-title>
    </b-card-header>

    <b-card-body
      :style="`max-height: ${height}`"
      class="overflow-auto"
    >
      <b-overlay :show="isLoading">
        <slot />
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import { $themeColors } from '@themeConfig'

export default {
  props: ['title', 'section', 'height'],
  data() {
    return {
      isLoading: false,
    }
  },

  watch: {
  },
  mounted() {
  },
  methods: {

  },
}
</script>

<template>
  <div>
    <div
      class="d-flex flex-column flex-gap-md corporate-directory-details overflow-auto px-1"
      style="height: 80vh;"
    >
      <div v-if="$isNotEmpty(corporateDetails.chart_data.recruitment_activities)" id="recruitmentActivities">
        <ChartComponent
          :chart-data="corporateDetails.chart_data.recruitment_activities"
          :height="'200'"
          :chart-type="'line'"
          title="Recruitment Activities"
        />
      </div>

      <div class="d-flex flex-row flex-wrap flex-gap-md w-100">
        <CustomAnalyticCard
          id="uniqueRoles"
          height="42vh"
          class="flex-fill mx-2 mx-lg-0"
          title="Unique Roles"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Role Title</td>
              </tr>
            </thead>

            <tbody
              v-if="corporateDetails.chart_data.unique_roles"
            >
              <tr
                v-for="item, index in corporateDetails.chart_data.unique_roles"
                :key="item.key"
                :class="index%2 === 1 ? 'bg-primary bg-lighten-6' : ''"
              >
                <td>{{ item.position }}</td>
              </tr>
            </tbody>

          </table>
        </CustomAnalyticCard>

        <CustomAnalyticCard
          id="listedRoles"
          height="42vh"
          class="flex-fill"
          title="Listed Roles"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Role Title</td>
                <!-- <td>Publication Status</td> -->
                <td>Public On</td>
                <td>Source</td>
              </tr>
            </thead>

            <tbody
              v-if="corporateDetails.chart_data.listed_roles"
            >
              <tr
                v-for="item, index in corporateDetails.chart_data.listed_roles"
                :key="item.key"
                :class="index%2 === 1 ? 'bg-primary bg-lighten-6' : ''"
              >
                <td>{{ item.position }}</td>
                <!-- <td>
                  <CellRendererCategory :params="{publication_status: item.publication_status}" />
                </td> -->
                <td>{{ $formatDatetime(item.published_on) }}</td>
                <!-- <td>{{ item.source_name }}</td> -->
                <td>HHQ</td> <!-- all will be HHQ for now as requested -->
              </tr>
            </tbody>

          </table>
        </CustomAnalyticCard>
      </div>

      <div class="d-flex flex-row flex-wrap flex-gap-md w-100">
        <CustomAnalyticCard
          id="employeeList"
          height="42vh"
          class="flex-fill"
          title="Employees (Based on Public Records)"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Position</td>
                <td>Years in Orgnisation</td>
              </tr>
            </thead>

            <tbody v-if="corporateDetails.chart_data.employee_list">
              <tr
                v-for="item, index in corporateDetails.chart_data.employee_list"
                :key="item.key"
                :class="index%2 === 1 ? 'bg-primary bg-lighten-6' : ''"
              >
                <td>{{ item.fullname }}</td>
                <td>{{ $isNotEmpty(item.position_in_company) ? item.position_in_company : '-' }}</td>
                <td>{{ $isNotEmpty(item.years_in_organisation) ? item.years_in_organisation : '-' }}</td>
              </tr>
            </tbody>

          </table>
        </CustomAnalyticCard>
      </div>
    </div>
  </div>
</template>

<script>
import ChartComponent from '@/components/ChartComponent.vue'
import CustomAnalyticCard from '@/components/CustomAnalyticCard.vue'
import CellRendererCategory from '@/views/event-management-system/EventManager/cell-renderer/CellRendererCategory.vue'

export default {
  components: {
    ChartComponent,
    CustomAnalyticCard,
    CellRendererCategory,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      corporateDetails: {
        data: {},
        chart_data: {},
      },
    }
  },

  computed: {
  },

  watch: {
  },

  created() {
    this.getCorporateDetails()
  },

  mounted() {
  },

  methods: {
    getCorporateDetails() {
      this.$http
        .get(`/api/corporate-details/${this.data.company_uen_no}`)
        .then(response => {
          const { success, output, message } = response.data

          if (success) {
            this.corporateDetails = output
          }
        })
        .catch(() => {
          //
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.corporate-directory-details {

  #recruitmentActivities {
    width: 100%;
  }
}

.custom-table {
  width: 100%;

  thead {
    line-height: 3.5rem;
    background: $primary;
    color: $white;
  }
  tbody {
    line-height: 3rem;
  }

  tbody tr:nth-child(even) {
    background: lighten($primary, 48%);
  }

  thead td {
    padding-inline: 1rem;
  }

  tbody td {
    padding-inline: 1rem;
  }
  .row-summary {
    background: lighten($primary, 40%);
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 3.5rem;
  }
}
</style>



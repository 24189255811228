<template>
  <div
    id="div-with-loading"
    class="vs-con-loading__container mx-12 mt-8"
  >
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <sideview
        :is-sidebar-active="sidebarActivated"
        :data="billing_info"
        @closeSidebar="toggleDataSidebar"
      />

      <b-modal
        v-model="promo_popup"
        centered
        hide-footer
        title="Promo Code"
      >
        <b-row>
          <h6 class="mx-auto">
            Enter your promo code below if you have one
          </h6>
        </b-row>
        <b-row>
          <b-form-input
            v-model="promo_code"
            class="sm:w-full mx-1"
            placeholder="PROMO CODE"
          />
        </b-row>

        <div class="flex w-full mx-auto my-1">
          <b-button
            variant="danger"
            class="col mx-1 w-1/2 mt-1"
            @click="skipPromo"
          >Skip</b-button>
          <b-button
            variant="warning"
            class="col mx-1 w-1/2 mt-1"
            @click="validatePromo"
          >Validate Promo Code</b-button>
        </div>
      </b-modal>

      <div
        v-if="$checkRights($route.meta.section, 'read')"
        class="row px-2 mt-1"
      >
        <b-card class="w-100 ">
          <div class="mb-1">
            <div class="d-flex flex-wrap">
              <b-col
                sm="12"
                lg="4"
              >
                <h6 class="mt-1">
                  Company Name
                </h6>
                <CompanySearch
                  id="company-name"
                  v-model="selected_company"
                  name="company-name"
                  placeholder="Start typing to get company name suggestions"
                  :multiple="false"
                  @company-selected="handleCompanyInput"
                />

                <b-button
                  class="mt-1"
                  variant="primary"
                  @click="popup_active_monitoring_list.state = !popup_active_monitoring_list.state"
                >
                  List of Monitored Corporate
                </b-button>
              </b-col>

              <b-col
                sm="12"
                lg="4"
              >
                <h6 class="mt-1">
                  Industry
                </h6>
                <v-select
                  v-model="selected_industries"
                  class="text-white"
                  multiple
                  autocomplete
                  placeholder="Start typing to get industry suggestions"
                  :options="industryOptions"
                  label="text"
                  :reduce="(value) => value.value"
                />
              </b-col>

              <b-col
                sm="12"
                lg="4"
              >
                <h6 class="mt-1">
                  Recruitment Status
                </h6>
                <v-select
                  v-model="selected_status"
                  label="text"
                  class=""
                  autocomplete
                  placeholder="Select recruitment status"
                  :options="recruitment_statuses"
                />
              </b-col>
            </div>
          </div>
        </b-card>
      </div>

      <b-row class="px-2">
        <hr>
        <h4
          v-if="!$checkRights($route.meta.section, 'read') || companiesData.length < 1"
          class="grey mx-auto text-center"
        >
          No data available for now
        </h4>
        <b-row class="w-100">
          <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
          <!-- .slice(i * 4, (i + 1) * 4) -->

          <b-col
            v-for="(item, index) in companiesData"
            :key="index"
            sm="12"
            md="6"
            class=""
          >
            <b-card class="">
              <div class="flex flex-wrap flex-fill justify-around">
                <div class="">
                  <div class="d-flex justify-content-between align-items-start">
                    <h5 class="font-weight-bolder">
                      {{ item.company_name }} ({{ item.company_uen_no }})
                    </h5>
                    <!-- <b-badge pill
                    :key="index"
                    v-for="(text, index) in item.preferences"
                    variant="warning"
                    class="h-4 mt-2 ml-4"
                    >{{ capitalizeFirstLetter(text) }}</b-badge
                  >-->
                    <div class="">
                      <feather-icon
                        icon="BellIcon"
                        class="cursor-pointer text-primary"
                        size="30"
                        :class="item.user_active_monitor_state ? 'fill-primary-lighten-3' : ''"
                        @click="toggleActiveMonitoring(item.id, index)"
                      />
                    </div>
                  </div>

                  <div class="row mt-1 self-start">
                    <div class="d-flex">
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="cursor-pointer ml-1"
                      />
                      <h6 class="">
                        Primary SSIC: {{ item.primary_ssic }}
                        {{ checkDescription(item.primary_ssic_description) }}
                      </h6>
                    </div>
                  </div>

                  <div class="row mt-1 self-start">
                    <div class="d-flex">
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="cursor-pointer ml-1"
                      />
                      <h6 class="">
                        Secondary SSIC: {{ item.secondary_ssic }}
                        {{ checkDescription(item.secondary_ssic_description) }}
                      </h6>
                    </div>
                  </div>

                  <div class="row mt-1 self-start">
                    <div class="d-flex">
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="cursor-pointer ml-1"
                      />

                      <h6 class="">
                        Recruitment Status: We believe that it's
                        <span
                          v-if="item.recruitment_status == 'active'"
                          class="text-success"
                        >
                          {{ item.recruitment_status }}
                        </span>
                        <span
                          v-else
                          class="text-danger"
                        >
                          inactive
                        </span>
                      </h6>
                    </div>
                  </div>

                  <div
                    v-if="item.private"
                    class="row mt-1 self-start"
                  >
                    <!-- <feather-icon
                    icon="LayersIcon"
                    class="cursor-pointer"
                  ></feather-icon> -->
                    <!-- <h5 class="ml-2 mt-1 text-primary">
                    {{ item.company.toUpperCase() }}
                  </h5> -->
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="cursor-pointer ml-1"
                    />
                    <h6 class="ml-2 mt-1">
                      Active since {{ item.licence_date }}
                    </h6>
                  </div>
                </div>
                <hr>
                <div
                  class="d-flex justify-content-between align-items-center mt-2 w-full self-center"
                >
                  <div class="self-center text-bottom">
                    <h6 style="font-weight: bold; font-style: italic">
                      Update as of {{ new Date().toLocaleDateString() }}
                    </h6>
                  </div>

                  <div>
                    <b-button
                      variant="primary"
                      @click="popup_corporate_details.state = true, popup_corporate_details.data = item"
                    >
                      View More
                    </b-button>
                  </div>

                  <div v-if="false">
                    <b-button
                      to="/corporate-directory-details"
                      class="mr-2 ml-2"
                      variant="outline-primary"
                    >Email Address</b-button>
                    <b-button
                      to="/corporate-directory-details"
                      class="mr-2 ml-2"
                      variant="outline-primary"
                    >Phone Number</b-button>
                    <b-button
                      to="/corporate-directory-details"
                      class="mr-2 ml-2"
                      variant="outline-primary"
                    >View More Info</b-button>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <div class="mt-2 d-flex align-items-center justify-content-center w-100">
            <b-pagination
              :value="serverParams.page"
              :total-rows="serverParams.totalRows"
              :per-page="serverParams.perPage"
              first-number
              last-number
              align="center"
              prev-class="prev-item"
              next-class="next-item"
              class=""
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-row>
      </b-row>

      <div
        v-if="$checkRights(this.$route.meta.section, 'read')"
        class="row mx-auto"
      >
        <b-button
          v-if="companiesData.length >= default_count"
          class="shadow-lg mx-auto"
          variant="warning"
          @click="viewMore"
        >View more companies</b-button>
      </div>

      <hr v-if="$checkRights(this.$route.meta.section, 'read')">

      <b-card
        v-if="popup_payment"
        class="container my-4"
      >
        <div class="row w-full my-4">
          <h6 class="mx-auto text-center">
            To view the corporate directory you need to have an active
            subscription.
          </h6>
        </div>
        <stripe-checkout
          ref="checkoutRef"
          mode="subscription"
          :pk="publishableKey"
          :customer-email="customer_email"
          :line-items="corporateItems"
          :success-url="successURL"
          :cancel-url="cancelURL"
          @loading="v => (loading = v)"
        />
        <b-button
          variant="warning"
          class="row mx-auto mt-4"
          @click="subscribe('corporate_directory')"
        >Subscribe now for S$20 per Month</b-button>
      </b-card>
    </b-overlay>

    <b-modal
      v-model="popup_corporate_details.state"
      centered
      hide-footer
      size="xl"
      :title="`Corporate Details - ${popup_corporate_details.data.company_name} (${popup_corporate_details.data.company_uen_no})`"
    >
      <CorporateDirectoryViewMore 
        :data="popup_corporate_details.data"
      />
    </b-modal>

    <b-modal
      v-model="popup_active_monitoring_list.state"
      centered
      hide-footer
      size="lg"
      title="List of Monitored Corporate"
    >
      <div class="">
        <h4>List of the comapanies in your watchlist</h4>
        <div
          class="mt-1 d-flex align-items-center justify-content-center"
        >
          <div
            v-if="$isNotEmpty(popup_active_monitoring_list.data)"
            class="w-100"
          >
            <b-table
              hover
              :current-page="popup_active_monitoring_list.serverParams.currentPage"
              :per-page="popup_active_monitoring_list.serverParams.perPage"
              :total-rows="popup_active_monitoring_list.serverParams.totalRows"
              :items="popup_active_monitoring_list.data"
              :fields="popup_active_monitoring_list.fields"
              class="w-100"
            >
              <template #cell(actions)="{item}">
                <div class="d-flex flex-gap-md align-items-center justify-contens-center- ">
                  <feather-icon
                    icon="BellIcon"
                    class="cursor-pointer text-primary fill-primary-lighten-3"
                    size="30"
                    @click="toggleActiveMonitoring(item.id, index)"
                  />

                  <b-button
                    variant="primary"
                    @click="popup_corporate_details.state = true, popup_corporate_details.data = item"
                  >
                    View More
                  </b-button>
                </div>
              </template>
            </b-table>

            <b-pagination
              v-model="popup_active_monitoring_list.serverParams.currentPage"
              :per-page="popup_active_monitoring_list.serverParams.perPage"
              :total-rows="popup_active_monitoring_list.serverParams.totalRows"
              aria-controls="my-table"
              class="mt-2 float-right"
            />
          </div>

          <div
            v-else
            class="py-3"
          >
            No company added to watchlist yet
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import CompanySearch from '@/components/CompanySearch.vue'
import Sideview from '../payments/Sideview.vue'
import CorporateDirectoryViewMore from './CorporateDirectoryViewMore.vue'

export default {
  components: {
    StripeCheckout,
    Sideview,
    vSelect,
    BCard,
    BTab,
    BTabs,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    CorporateDirectoryViewMore,
    CompanySearch,
  },
  data() {
    return {
      purchase_category: '',
      secondary_code: '',
      promo_code: '',
      promo_popup: false,
      customer_email: '',
      sidebarActivated: false,
      proceed_transaction: false,
      billing_info: {},
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      corporateItems: [
        {
          price: 'price_1Jhi4jI2ri5g5XG9rpt34CoH',
          //  price: "price_1Ji2JbI2ri5g5XG9O7TH1lMU",
          quantity: 1,
        },
      ],
      successURL: 'https://dashboard.headhuntershq.com/payment-success',
      cancelURL: 'https://dashboard.headhuntershq.com/corporate-directory',
      blur_check: 'blur',
      job_link: '',
      not_fullname: '',
      not_email: '',
      not_position: '',
      not_industry: '',
      event_id: '',
      fullname: '',
      email: '',
      resume_uploaded: false,
      show_inside: false,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,
      item_id: '',
      job: {},
      companies: [],
      registered: [],
      companiesData: [],
      select_tags: [],
      select_name: '',
      all_positions: [],
      selected_status: '',
      selected_industries: [],
      selected_company: '',
      current_user: '',
      position: '',
      description: '',
      job_count: 0,
      default_count: 50,
      pagination: {
        skip: 0,
        limit: 10,
      },
      selectedRows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          company_name: 'asc'
        },
        page: 1,
        perPage: 10,
        totalRows: 0,
      },
      recruitment_statuses: [
        { text: 'All recruitment status', value: 0 },
        { text: '❌Inactive', value: 1 },
        { text: '✔️Active', value: 2 },
      ],
      // industries: [
      //   {
      //     text: 'Accommodation and food service activities',
      //     value: 'Accommodation and food service activities',
      //   },
      //   {
      //     text: 'Administrative and support service activities',
      //     value: 'Administrative and support service activities',
      //   },
      //   { text: 'Agriculture and fishing', value: 'Agriculture and fishing' },
      //   {
      //     text: 'Arts, entertainment and recreation',
      //     value: 'Arts, entertainment and recreation',
      //   },
      //   { text: 'Construction', value: 'Construction' },
      //   { text: 'Education', value: 'Education' },
      //   {
      //     text: 'Electricity,gas,steam and air-conditioning supply',
      //     value: 'Electricity,gas,steam and air-conditioning supply',
      //   },
      //   {
      //     text: 'Financial and insurance activities',
      //     value: 'Financial and insurance activities',
      //   },
      //   {
      //     text: 'Health and social services',
      //     value: 'Health and social services',
      //   },
      //   {
      //     text: 'Information and communications',
      //     value: 'Information and communications',
      //   },
      //   { text: 'Manufacturing', value: 'Manufacturing' },
      //   { text: 'Mining and quarrying', value: 'Mining and quarrying' },
      //   {
      //     text: 'Other service activities',
      //     value: 'Other service activities',
      //   },
      //   {
      //     text: 'Professional, scientific and technical activities',
      //     value: 'Professional, scientific and technical activities',
      //   },
      //   {
      //     text: 'Public administration and defence',
      //     value: 'Public administration and defence',
      //   },
      //   { text: 'Real estate activities', value: 'Real estate activities' },
      //   {
      //     text: 'Transportation and storage',
      //     value: 'Transportation and storage',
      //   },
      //   {
      //     text: 'Water supply;sewerage waste management and remediation activities',
      //     value:
      //       'Water supply;sewerage waste management and remediation activities',
      //   },
      //   {
      //     text: 'Wholesale and retail trade',
      //     value: 'Wholesale and retail trade',
      //   },
      // ],
      industryOptions: [],
      dates: [],
      popup_payment: false,
      account_status: { corporate_directory: { active: false } },
      payment_id: '',
      instance: null,

      popup_corporate_details: {
        state: false,
        data: {},
      },
      popup_active_monitoring_list: {
        state: false,
        data: [],
        fields: [],
        serverParams: {
          currentPage: 1,
          perPage: 5,
          totalRows: 1,
        },
      },
    }
  },
  computed: {
    eventsGroups() {
      return Array.from(Array(Math.ceil(this.companiesData.length / 4)).keys())
    },
  },
  watch: {
    selected_status: {
      handler(val, oldVal) {
        this.updateFilters('recruitment_status', val.value)
        // this.filterAgencies()
      },
    },
    selected_industries: {
      handler(val, oldVal) {
        this.getDirectory()
        // this.updateFilters('selected_industries', val)
        // this.filterAgencies()
        // this.filter_events();
      },
    },
    selected_company: {
      handler(val, oldVal) {
        this.filterAgencies()
        // this.filter_events();
      },
    },
    'popup_active_monitoring_list.state': function () {
      this.getListOfActiveMonitoring()
    },
    deep: true,
  },

  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}

    this.getIndustryOptions()
  },

  created() {
    // const { token } = this.$store.state.auth.ActiveUser || {}
    this.email_address = this.$store.state.auth.ActiveUser.email
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    try {
      this.resume_uploaded = this.$store.state.auth.ActiveUser.resume_upload
    } catch (error) {}

    this.getDirectory()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getDirectory()
    },
    onPageChange(params) {
      const prevSelectedROws = this.selectedRows
      this.updateParams({ page: params.currentPage })
      this.updateParams({ selectedRows: prevSelectedROws })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.serverParams.page = 1
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      if (this.$isNotEmpty(value)) {
        currentFilters[column] = value
      } else {
        delete currentFilters[column]
      }
      this.updateParams({ columnFilters: currentFilters })
    },

    checkDescription(description) {
      return description ? `(${description})` : ''
    },
    validatePromo() {
      if (this.promo_code.length > 2) {
        this.$http
          .get(`/api/promo-code/${this.promo_code}/${this.secondary_code}`)
          .then(response => {
            if (response.data.success) {
              this.promo_popup = false
              this.promo_code = ''
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Promo Code Alert',
                    icon: 'CheckCircleIcon',
                    text: 'Your subscription has been activated successfully',
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              window.location.reload()
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Promo Code Alert',
                    icon: 'AlertCircleIcon',
                    text: 'The promo code you have entered is either invalid or has already expired.',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Promo Code Notification',
              icon: 'AlertCircleIcon',
              text: 'A valid promo code is required before validation.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    skipPromo() {
      const section = this.purchase_category
      this.promo_popup = false

      this.$refs.checkoutRef.redirectToCheckout()
    },
    toggleDataSidebar() {
      this.sidebarActivated = false
    },
    getBillingInfo() {
      this.$http
        .get('/api/billing-info')
        .then(response => {
          if (response.data.success) {
            const results = response.data.output
            this.billing_info = results
            const neededKeys = [
              'phone_number',
              'email',
              'address_1',
              'first_name',
              'last_name',
              'company_name',
            ]

            if (
              neededKeys.every(key => Object.keys(this.billing_info).includes(key))
            ) {
              this.proceed_transaction = true
            } else {
              this.proceed_transaction = false
            }
          }
        })
        .catch(error => {})
    },
    subscribe(section) {
      this.getBillingInfo()
      this.purchase_category = section
      if (section === 'corporate_directory') {
        this.secondary_code = 300
        this.successURL = `https://dashboard.headhuntershq.com/payment-success/${this.payment_id}/300`
      }
      if (this.proceed_transaction) {
        this.promo_popup = true
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Billing Notification',
              icon: 'AlertCircleIcon',
              text: 'Please update your billing information before subscribing',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )

        this.sidebarActivated = true
      }
    },
    generate_payment_id() {
      this.$http
        .get('/api/payment-id/300')
        .then(response => {
          if (response.data.success) {
            this.payment_id = response.data.payment_id
          }
        })
        .catch(error => {})
    },

    handleCompanyInput: debounce(function (text) {
      // if (text.length === 0) {
      //   this.selected_company = ''
      //   this.filterAgencies()
      // }
      // this.getEmployers(text)
      if (this.$isNotEmpty(text)) {
        this.$set(this, 'selected_company', text.company_name)
        this.updateFilters('company_name', this.selected_company)
      } else {
        this.updateFilters('company_name', null)
      }
    }, 1000),

    // handleCompanyInput:debounce(() => {
    //
    //   }, 1000),

    handleIndustryInput(text) {
      return text
    },
    getEmployers(query) {
      this.companies = []

      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          if (response.data.success) {
            const records = response.data.data
            records.map(item => {
              if (!this.companies.includes(item.text)) {
                this.companies.push(item.text)
              }
            })
          }
        })
        .catch(error => {})
    },
    check_empty(value) {
      if (value) {
        if (value.length > 4) {
          return true
        }
        return false
      }
      return true
    },
    send_response() {
      if (
        this.check_empty(this.not_fullname)
        && this.check_empty(this.not_email)
        && this.check_empty(this.not_position)
      ) {
        const all_data = {
          fullname: this.not_fullname,
          email: this.not_email,
          position: this.not_position,
          industry: this.not_industry,
        }
        this.$http
          .post('/api/response', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Response Sent',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.not_position = ''
              this.not_fullname = ''
              this.not_email = ''
              this.not_industry = ''
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Response Failed',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Response Failed',
              icon: 'AlertCircleIcon',
              text: 'Please fill all form values correctly.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    viewMore() {
      this.default_count *= 2
      this.getDirectory()
    },

    filterAgencies() {
      this.getDirectory()
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    filter_events(data) {
      // let selected_tags = [];
      // for (let data of this.categories) {
      //   for (let categ of this.select_tags) {
      //     if (data.value === categ) {
      //       selected_tags.push(data.text);
      //     }
      //   }
      // }

      // let selected_companies = [];
      // for (let data of this.companies) {
      //   for (let categ of this.select_company) {
      //     if (data.value === categ) {
      //       selected_companies.push(data.text);
      //     }
      //   }
      // }

      let new_events = false
      if (data.ea_name.includes(this.select_name)) {
        selected_date = data.text
        new_events = true
      }

      // let new_events = false;

      // let comp =
      //   selected_companies.length > 0
      //     ? selected_companies.includes(data.company)
      //     : true;
      // let tags =
      //   selected_tags.length > 0
      //     ? selected_tags.some((v) => data.tags.includes(v))
      //     : true;
      // let dates = data.position
      //   .toLowerCase()
      //   .includes(this.select_position.toLowerCase());

      // if (comp && tags && dates) {
      //   new_events = true;
      // }

      return new_events
      // this.companiesData = new_events;
    },

    selectOne(jobid, all_data) {
      let active_job = {}
      for (const item of all_data) {
        if (item._id === jobid) {
          active_job = item
        }
      }
      if (Object.keys(active_job).length !== 0) {
        this.viewMore(active_job)
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Alert',
              icon: 'AlertCircleIcon',
              text: "It seems this job position has been filled or doesn't exists.",
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    editEvent(item) {
      this.action = 'update'
      this.item_id = item._id
      this.position = item.position
      this.description = item.description
      //   this.select_date = item.date;
      //   this.select_tags = item.tags;
      this.image_url = item.image_url
      this.popupActive = true
    },

    getDirectory() {
      this.isLoading = true
      const { id } = this.$store.state.auth.ActiveUser
      this.companiesData = []
      this.events_part_data = []
      const { token } = this.$store.state.auth.ActiveUser

      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      let query = ''
      if (!this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
        query += `${this.$attachQuerySymbol(query)}pagination=${JSON.stringify(this.pagination)}`
      }
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .post(`/api/corporate-companies${query}`, {
          form_data: {
            selected_industries: this.selected_industries, // * list could get too long to be put into query
          },
        })
        .then(response => {
          const { params, output } = response.data
          this.companiesData = output
          this.serverParams = params
          this.isLoading = false
          // if (response.data.success) {
          //   this.account_status = response.data.account_status
          //   if (this.account_status.corporate_directory.active) {
          //     this.events_part_data = response.data.message
          //     this.blur_check = ''
          //     this.popup_payment = false
          //   } else {
          //     this.blur_check = 'blur'
          //     this.popup_payment = true
          //   }

          //   // Array.prototype.subarray = function (start, end) {
          //   //   if (!end) {
          //   //     end = -1;
          //   //   }
          //   //   return this.slice(start, this.length + 1 - end * -1);
          //   // };
          //   // this.companiesData = this.events_part_data.slice(
          //   //   0,
          //   //   this.default_count + 1
          //   // );
          //   this.companiesData = this.events_part_data
          //   this.isLoading = false
          // } else {
          //   this.event_exist = false
          //   this.isLoading = false
          // }
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    extract_values() {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }
      let selected_date = ''
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      }
      return all_data
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    updateFiles(input) {
      if (this.email) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Resume upload in progress.',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = e => {}
          const fd = new FormData()
          fd.append('file', input.target.files[0])
          delete this.$http.defaults.headers.common.Authorization
          this.$http
            .post(`/api/upload-resume/${this.email}`, fd)
            .then(response => {
              this.resume_uploaded = true
              // let image_url = response.data.data.display_url;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
            .catch(error => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: 'Resume upload failed. Try again later.',
                    variant: 'warning',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
          reader.readAsDataURL(input.target.files[0])
        }
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Please fill the form above to proceed.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    toggleActiveMonitoring(company_id, index=null) {
      this.$http
        .post(`/api/corporate-directory/active-monitoring`, {
          form_data: {
            company_id,
          },
        })
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            if (this.$isNotEmpty(index)) {
              this.companiesData[index].user_active_monitor_state = !this.companiesData[index].user_active_monitor_state
            } else {
              this.getDirectory()
              this.getListOfActiveMonitoring()
            }
          }
        })
        .catch(error => {
          //
        })

    },

    getListOfActiveMonitoring() {
      const params = `?params=${JSON.stringify(this.popup_active_monitoring_list.serverParams)}`

      this.$http
        .get(`/api/corporate-directory/active-monitoring-list${params}`)
        .then(response => {
          const { success, output, message, params } = response.data
          if (success) {
            this.popup_active_monitoring_list.data = output
            this.popup_active_monitoring_list.fields = [
              'company_name', 'uen_no', 'actions',
            ]
            this.popup_active_monitoring_list.serverParams = params
          }
        })
        .catch(() => {})
    },

    getIndustryOptions() {
      this.$http
        .get(`/api/get-industry-options`)
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            this.industryOptions = output
          }
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss">
.blur {
  filter: blur(16px);
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>

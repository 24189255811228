<template>
  <b-card
    no-body
    class="h-100 m-0 p-0"
  >
    <b-card-header class="align-items-baseline my-0 py-0">
      <div>
        <b-card-title class="m-0 p-0">
          {{ title }}
        </b-card-title>
      </div>
    </b-card-header>

    <b-card-body class="pb-0 h-100 mx-1">
      <!-- apex chart -->
      <b-overlay :show="isLoading">
        <vue-apex-charts
          v-if="!isLoading"
          :type="chartType"
          :height="`${height}`"
          :options="applicationsChartData.chartOptions"
          :series="applicationsChartData.series"
        />
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },

  props: {
    title: {
      type: String,
      default: 'Chart',
    },
    chartData: {
      type: Object,
      default: () => {},
    }, 
    chartType: {
      type: String,
      default: 'line',
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '200',
    },
  },

  data() {
    return {
      isLoading: false,

      applicationsChartData: {
        series: [],
        chartOptions: {},
      },

      // applicationsChartData: {
      //   series: [
      //     {
      //       name: this.title,
      //       data: [],
      //     },
      //   ],
      //   chartOptions: {
      //     chart: {
      //       toolbar: { show: false },
      //       zoom: { enabled: false },
      //       type: this.chartType,
      //       dropShadow: {
      //         enabled: true,
      //         top: 18,
      //         left: 2,
      //         blur: 5,
      //         opacity: 0.2,
      //       },
      //       offsetX: -10,
      //     },
      //     stroke: {
      //       curve: 'smooth',
      //       width: 4,
      //     },
      //     grid: {
      //       borderColor: '#ebe9f1',
      //       padding: {
      //         top: -20,
      //         bottom: 5,
      //         left: 20,
      //       },
      //     },
      //     legend: {
      //       show: false,
      //     },
      //     colors: ['#df87f2'],
      //     fill: {
      //       type: 'gradient',
      //       gradient: {
      //         shade: 'dark',
      //         inverseColors: false,
      //         gradientToColors: [$themeColors.primary],
      //         shadeIntensity: 1,
      //         type: 'horizontal',
      //         opacityFrom: 1,
      //         opacityTo: 1,
      //         stops: [0, 100, 100, 100],
      //       },
      //     },
      //     markers: {
      //       size: 0,
      //       hover: {
      //         size: 5,
      //       },
      //     },
      //     xaxis: {
      //       labels: {
      //         offsetY: 5,
      //         style: {
      //           colors: '#b9b9c3',
      //           fontSize: '0.857rem',
      //         },
      //       },
      //       axisTicks: {
      //         show: false,
      //       },
      //       // categories: [
      //       //   'Jan',
      //       //   'Feb',
      //       //   'Mar',
      //       //   'Apr',
      //       //   'May',
      //       //   'Jun',
      //       //   'Jul',
      //       //   'Aug',
      //       //   'Sep',
      //       //   'Oct',
      //       //   'Nov',
      //       //   'Dec',
      //       // ],
      //       categories: [
      //       ],
      //       axisBorder: {
      //         show: false,
      //       },
      //       tickPlacement: 'on',
      //     },
      //     yaxis: {
      //       tickAmount: 5,
      //       labels: {
      //         style: {
      //           colors: '#b9b9c3',
      //           fontSize: '0.857rem',
      //         },
      //         formatter(val) {
      //           return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
      //         },
      //       },
      //     },
      //     tooltip: {
      //       x: { show: false },
      //     },
      //   },
      // },
    }
  },

  watch: {
    chartData(newVal, oldVal) {
      this.updateData(newVal)
    },
  },

  mounted() {
    if (Object.keys(this.chartData).length > 0) {
      this.updateData(this.chartData)
    }

    for (let step = 1; step < 30; step++) {
      this.applicationsChartData.chartOptions.xaxis.categories.push(step)
    }
  },

  methods: {
    updateData(newVal) {
      this.$set(this, 'isLoading', true)
      const chartData = newVal

      // this.applicationsChartData = {
      //   series: [
      //     {
      //       name: this.title,
      //       data: Object.values(chartData),
      //     },
      //   ],
      //   chartOptions: {
      //     chart: {
      //       toolbar: { show: false },
      //       zoom: { enabled: false },
      //       type: this.chartType,
      //       dropShadow: {
      //         enabled: true,
      //         top: 18,
      //         left: 2,
      //         blur: 5,
      //         opacity: 0.2,
      //       },
      //       offsetX: -10,
      //     },
      //     stroke: {
      //       curve: 'smooth',
      //       width: 4,
      //     },
      //     grid: {
      //       borderColor: '#ebe9f1',
      //       padding: {
      //         top: -20,
      //         bottom: 5,
      //         left: 20,
      //       },
      //     },
      //     legend: {
      //       show: false,
      //     },
      //     colors: ['#df87f2'],
      //     fill: {
      //       type: 'gradient',
      //       gradient: {
      //         shade: 'dark',
      //         inverseColors: false,
      //         gradientToColors: [$themeColors.primary],
      //         shadeIntensity: 1,
      //         type: 'horizontal',
      //         opacityFrom: 1,
      //         opacityTo: 1,
      //         stops: [0, 100, 100, 100],
      //       },
      //     },
      //     markers: {
      //       size: 0,
      //       hover: {
      //         size: 5,
      //       },
      //     },
      //     xaxis: {
      //       labels: {
      //         offsetY: 5,
      //         style: {
      //           colors: '#b9b9c3',
      //           fontSize: '0.857rem',
      //         },
      //       },
      //       axisTicks: {
      //         show: false,
      //       },
      //       categories: Object.keys(chartData),
      //       axisBorder: {
      //         show: false,
      //       },
      //       tickPlacement: 'on',
      //     },
      //     yaxis: {
      //       tickAmount: 5,
      //       labels: {
      //         style: {
      //           colors: '#b9b9c3',
      //           fontSize: '0.857rem',
      //         },
      //         formatter(val) {
      //           return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
      //         },
      //       },
      //     },
      //     tooltip: {
      //       x: { show: false },
      //     },
      //   },
      // }
      this.$set(this, 'applicationsChartData', {
        series: [
          {
            name: this.title,
            data: Object.values(chartData),
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: this.chartType,
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              // top: -20,
              // bottom: -5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: Object.keys(chartData),
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      })

      setTimeout(() => {
        this.$set(this, 'isLoading', false)
      }, 100)
    },
  },
}
</script>
